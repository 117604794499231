import React, { useEffect } from "react"
import styled from "styled-components"
import ReactCompareImage from "react-compare-image"
import AOS from "aos"
import "aos/dist/aos.css"

const BlogWrapper = styled.section`
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4rem;
  font-family: "Montserrat", sans-serif;
`
const ArticleListWrapper = styled.article`
  max-width: 100%;
  min-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 2rem;
  @media (max-width: 768px) {
    padding: 1rem 1rem;
  }
`
const ArticlePhotoCompareWrapper = styled.article`
  max-width: 100%;
  min-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 2rem;
  @media (max-width: 768px) {
    padding: 1rem 1rem;
  }
`

const ArticleWrapper = styled.article`
  max-width: 100%;
  min-width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 2rem;
  @media (max-width: 768px) {
    padding: 1rem 1rem;
  }
`

const StyledH1 = styled.h1`
  text-align: center;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  margin: 0;
  text-transform: uppercase;
`
const StyledH2 = styled.h2`
  text-align: center;
  font-family: "Parisienne";
  font-size: 4rem;
  margin-bottom: 0.5rem;
  padding: 0rem;
  text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`
const StyledH3 = styled.h3`
  text-align: center;
  font-family: "Parisienne";
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding: 0rem;
  text-shadow: 4px 4px 2px rgba(150, 150, 150, 1);
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`
const StyledParagraph = styled.p`
  text-align: center;
  letter-spacing: 1px;
  padding: 1rem 1rem;
  width: 80%;
  font-weight: 600;
  /* word-break: bre; */

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 0rem;
  }
`

const StyledList = styled.ul`
  width: 80%;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
`
const StyledListElement = styled.li`
  width: 100%;
  padding: 1rem 0rem;
  list-style: square;
  text-align: center;
  word-break: break-all;
`
const SinglePhotoWrapper = styled.div`
  max-width: 100%;
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 1rem 1rem;
  }
`
const SinglePhoto = styled.img`
  min-width: 80%;
  max-width: 80%;
  @media (max-width: 768px) {
    min-width: 100%;
    width: 100%;
  }
`
const ListPhotoWrapper = styled.div`
  max-width: 80%;
  padding: 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    padding: 1rem 1rem;
  }
`
const ListPhotoItem = styled.img`
  min-width: 30%;
  max-width: 80%;
  padding: 0.5rem 0.5rem;

  @media (max-width: 768px) {
    min-width: 50%;
    max-width: 100%;
  }
`
const PhotoCompareImageWrapper = styled.div`
  min-width: 60%;
  max-width: 80%;
  height: 70vh; ///do zmiany
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 768px) {
    min-width: 80%;
    max-width: 100%;
  }
`

const BlogArticles = allDataToRender => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])
  let sorted = allDataToRender.allDataToRender
  sorted.sort((b, a) => Date.parse(a.date) - Date.parse(b.date))
  // console.log(sorted)

  return (
    <BlogWrapper>
      <StyledH1>#Blog :)</StyledH1>
      {sorted.map(component => {
        if (component.listTitleText) {
          return (
            <ArticleListWrapper className="list" data-aos="fade-left">
              <StyledH2>{component.title}</StyledH2>
              <StyledH3>{component.date}</StyledH3>
              <StyledParagraph>{component.listTitleText}</StyledParagraph>
              <StyledList>
                <StyledListElement key={1}>
                  {component.listText}
                </StyledListElement>
                <StyledListElement key={2}>
                  {component.listText2}
                </StyledListElement>
              </StyledList>
              <SinglePhotoWrapper>
                <SinglePhoto
                  src={component.singlephoto.data.attributes.url}
                  alt={component.title}
                ></SinglePhoto>
              </SinglePhotoWrapper>
              <ListPhotoWrapper>
                {component.listImages.data.map(imagesList => {
                  return (
                    <ListPhotoItem
                      src={imagesList.attributes.url}
                      alt={component.title}
                    ></ListPhotoItem>
                  )
                })}
              </ListPhotoWrapper>
            </ArticleListWrapper>
          )
        } else if (component.description) {
          let photos = [{}]
          return (
            <ArticlePhotoCompareWrapper
              className="compare"
              data-aos="fade-right"
            >
              <StyledH2>{component.title}</StyledH2>
              <StyledH3>{component.date}</StyledH3>
              <StyledParagraph>{component.description}</StyledParagraph>

              {component.photo.data.forEach(singlePhoto => {
                photos = [...photos, { photo: singlePhoto.attributes.url }]
              })}

              <PhotoCompareImageWrapper>
                {/* <span>Przesuń suwak i porównaj</span> */}
                <ReactCompareImage
                  leftImage={photos[1].photo}
                  rightImage={photos[2].photo}
                  leftImageAlt="photo compare"
                  rightImageAlt="photo compare"
                  leftImageCss={{
                    padding: "0rem",
                  }}
                  rightImageCss={{
                    padding: "0rem",
                  }}
                />
              </PhotoCompareImageWrapper>
            </ArticlePhotoCompareWrapper>
          )
        } else if (component.firstText) {
          return (
            <ArticleWrapper className="article" data-aos="fade-left">
              <StyledH2>{component.title}</StyledH2>
              <StyledH3>{component.date}</StyledH3>
              <StyledParagraph>{component.firstText}</StyledParagraph>
              <StyledParagraph>{component.secondText}</StyledParagraph>
              <StyledParagraph>{component.thirdText}</StyledParagraph>
              <SinglePhotoWrapper>
                {component.pictures.data.map(singlePicture => {
                  return (
                    <>
                      <SinglePhoto
                        src={singlePicture.attributes.url}
                        alt={component.title}
                      ></SinglePhoto>
                    </>
                  )
                })}
              </SinglePhotoWrapper>
            </ArticleWrapper>
          )
        }
        return ""
      })}
    </BlogWrapper>
  )
}

export default BlogArticles
