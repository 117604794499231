import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { trackPromise, usePromiseTracker } from "react-promise-tracker"
import styled from "styled-components"
import BlogArticles from "../components/BlogArticles"

const LoaderWrapper = styled.div`
  height: 100vh;
`

const Ring = styled.div`
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background: transparent;
  border: 3px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  color: #cbb26a;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-shadow: 0 0 10px #cbb26a;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  ::before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid #cbb26a;
    border-right: 3px solid #cbb26a;
    border-radius: 50%;
    animation: animateC 2s linear infinite;
  }

  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
const RingSpan = styled.span`
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;

  ::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cbb26a;
    top: -6px;
    right: -8px;
    box-shadow: 0 0 20px #cbb26a;
  }
  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
`

const Blog = () => {
  const [articleList, setArticleList] = useState([])
  const [singleArticles, setSingleArticles] = useState({})
  const [photoCompareArticle, setPhotoCompareArticle] = useState()
  const { promiseInProgress } = usePromiseTracker()

  useEffect(() => {
    trackPromise(getArticleList())
    trackPromise(getSingleArticle())
    trackPromise(getPhotoCompareArticle())

    // getArticleList()
    // getSingleArticle()
    // getPhotoCompareArticle()

    // trackPromise(
    //   fetch(
    //     `https://arcane-fjord-39169.herokuapp.com/api/list-articles?populate=*`
    //   )
    //     .then(response => response.json())
    //     .then(resultData => {
    //       setArticleList(resultData)
    //     }),
    //   fetch(
    //     `https://arcane-fjord-39169.herokuapp.com/api/single-articles?populate=*`
    //   )
    //     .then(response => response.json())
    //     .then(resultData => {
    //       setSingleArticles(resultData)
    //     }),
    //   fetch(
    //     `https://arcane-fjord-39169.herokuapp.com/api/photo-compares?populate=*`
    //   )
    //     .then(response => response.json())
    //     .then(resultData => {
    //       setPhotoCompareArticle(resultData)
    //     })
    // )
  }, [])

  const getArticleList = async () => {
    const response = await fetch(
      `https://arcane-fjord-39169.herokuapp.com/api/list-articles?populate=*`
    )
    const resultData = await response.json()
    setArticleList(resultData)
  }

  const getSingleArticle = async () => {
    const response = await fetch(
      `https://arcane-fjord-39169.herokuapp.com/api/single-articles?populate=*`
    )
    const resultData = await response.json()
    setSingleArticles(resultData)
  }

  const getPhotoCompareArticle = async () => {
    const response = await fetch(
      `https://arcane-fjord-39169.herokuapp.com/api/photo-compares?populate=*`
    )
    const resultData = await response.json()
    setPhotoCompareArticle(resultData)
  }

  let allDataToRender = [
    {
      id: "",
      date: "",
      title: "",
      listTitleText: "",
      listText: "",
      listText2: "",
      singlephoto: "",
      listImages: "",

      firstText: "",
      secondText: "",
      thirdText: "",
      pictures: "",

      description: "",
      photo: "",
    },
  ]

  // ARTICLE LIST QUERY

  if (articleList.data) {
    articleList.data.forEach(element => {
      allDataToRender = [
        ...allDataToRender,
        {
          id: element.id,
          date: element.attributes.date,
          title: element.attributes.title,
          listTitleText: element.attributes.listTitleText,
          listText: element.attributes.listText,
          listText2: element.attributes.listText2,
          singlephoto: element.attributes.singlephoto,
          listImages: element.attributes.listImages,

          firstText: "",
          secondText: "",
          thirdText: "",
          pictures: "",

          description: "",
          photo: "",
        },
      ]
    })
  }

  //SINGLE ARTICLE QUERY

  if (singleArticles.data) {
    singleArticles.data.forEach(element => {
      allDataToRender = [
        ...allDataToRender,
        {
          id: element.id,
          date: element.attributes.date,
          title: element.attributes.title,
          listTitleText: "",
          listText: "",
          listText2: "",
          singlephoto: "",
          listImages: "",

          firstText: element.attributes.firstText,
          secondText: element.attributes.secondText,
          thirdText: element.attributes.thirdText,
          pictures: element.attributes.pictures,

          description: "",
          photo: "",
        },
      ]
    })
  }

  //PHOTO COMPARE QUERY

  if (photoCompareArticle) {
    photoCompareArticle.data.forEach(element => {
      allDataToRender = [
        ...allDataToRender,
        {
          id: element.id,
          date: element.attributes.date,
          title: element.attributes.title,
          listTitleText: "",
          listText: "",
          listText2: "",
          singlephoto: "",
          listImages: "",

          firstText: "",
          secondText: "",
          thirdText: "",
          pictures: "",

          description: element.attributes.description,
          photo: element.attributes.photo,
        },
      ]
    })
  }

  return (
    <Layout>
      <Seo title="Blog" />

      {promiseInProgress === true ? (
        <LoaderWrapper>
          <Ring>
            wczytuję
            <RingSpan></RingSpan>
          </Ring>
        </LoaderWrapper>
      ) : (
        <BlogArticles allDataToRender={allDataToRender}></BlogArticles>
      )}
    </Layout>
  )
}

export default Blog
